<template>
  <div class="additional">
    <div class="additional__head">
      <h3 class="additional__title">
        <span v-if="isMain">{{ $t('Основні інгредієнти') }}</span>
        <span v-else>{{ $t('Додаткові інгредієнти') }}</span>
        <i v-if="maxQuantity">({{ $t('вибрано') }}
          {{ selectedQuantity }} з {{ maxQuantity }})</i>
      </h3>
      <div class="additional__subtitle" v-if="isMain">{{
          $t(`Важливо! Основні інгредієнти вже включені у вартість товару. Решта інгредієнтів (якщо такі будуть вибрані) оплачуються додатково. Під одним інгредієнтом слід розуміти одну порцію інгредієнта.`)
        }}
      </div>
    </div>

    <div class="additional__list">
      <template v-for="item in ingredients">
        <AdditionalItem :key="item.id"
                        :hidePrice="isMain"
                        :ingredient="item"
                        :quantity="getQuantity(item.id)"
                        :maxQuantity="maxQuantity"
                        :disabled="disabled"
                        @changeIngredient="$emit('changeIngredient', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import AdditionalItem from './AdditionalItem'

  export default {
    name: 'Additional',
    components: {AdditionalItem},
    props: {
      ingredients: null,
      ingredientsSelected: null,
      maxQuantity: {
        type: Number,
        default: 100
      },
      isMain: Boolean
    },
    computed: {
      selectedQuantity() {
        const ingredientsSelected = this.ingredientsSelected
        let selectedQuantity = 0
        for (const key in ingredientsSelected) {
          selectedQuantity += ingredientsSelected[key].quantity
        }
        return selectedQuantity
      },
      canAddQuantity() {
        return this.maxQuantity - this.selectedQuantity
      },
      disabled() {
        return !this.canAddQuantity
      },
    },
    methods: {
      getQuantity(ingredientId) {
        const ingredient = this.ingredientsSelected[ingredientId]
        return ingredient ? ingredient.quantity : 0
      },
    }
  }
</script>

<style>

</style>
