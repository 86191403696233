<template>
  <div class="product container">
    <CoolLightBox
        v-if="product && product.image_m"
        :items="[product]"
        srcName="image_m"
        :gallery="false"
        :index="indexImg"
        @close="onImgClick(null)"
        closeOnClickOutsideMobile
    />
    <div class="product__inner container-inner">
      <div class="product__inner-wrap">
        <div class="product__img-wrap">
          <div
              class="product__decor"
              :style="`background-image: url(${imgDecor})`"
          ></div>
          <picture class="product__frame">
            <img class="product__img lazyload"
                 @click="onImgClick(0)"
                 :alt="product.name"
                 sizes="(max-width: 1024px) 360px, 600px"
                 :src="$imgPlaceholder"
                 :data-src="product.image"
                 :srcset="$imgPlaceholder"
                 :data-srcset="product.image_xs ? `${product.image_xs} 480w, ${product.image_s} 600w` : null">
          </picture>
          <div class="product__labels"  v-if="product.badges && product.badges.length">
            <BaseLabel v-for="item in product.badges"
                       :key="item.id"
                       class="product__labels-item"
                       :label="item"/>
          </div>
        </div>
        <div class="product__content">
          <div class="product__details">
            <h3 class="product__title">{{ product.name }}</h3>
            <div class="product__subs" v-if="product.description">
              <div class="product__subs-inner">
                {{ stripHtml(product.description) }}
              </div>
            </div>

            <div class="product__params"
                 v-if="product.variants && product.variants.length > 1
                      || product.sauces && product.sauces.length">
              <div class="product__params-item" v-if="product.variants && product.variants.length > 1">
                <div class="product__params-title">{{ $t('Варіант') }}:</div>
                <ProductSize v-if="product.variants && product.variants.length > 1"
                             :variants="product.variants"
                             :selected="selectedVariant"
                             @selectVariant="onSelectVariant"
                             class="product__size"/>
              </div>
              <div class="product__params-item" v-if="product.sauces && product.sauces.length">
                <div class="product__params-title">{{ $t('Соус') }}:</div>
                <BaseSelect v-model="propertySelected"
                            :options="product.sauces"
                            textKey="name"
                            class="product__select"
                />
              </div>
            </div>
            <div class="product__params" v-if="allIngredientsList.length">
              <div class="product__params-item">
                <div class="product__params-title">
                  <button type="button"
                          @click="$scrollTo('#additional')"
                          class="product__btn-add">
                    <i>
                      <BaseSvg name="ico-plus-bold"/>
                    </i>
                    {{ $t('Додаткові інгредієнти') }}
                  </button>
                </div>
                <AdditionalTags :ingredients="ingredientsSelected"
                                @delete="deleteIngredient"
                                class="product__tags"
                />
              </div>
            </div>
            <div class="product__price-row" v-if="selectedVariant">
              <div class="product__price price _small">
                <span class="price__title">{{ $t('Ціна') }}:</span>
                <span class="price__val" v-if="categoryDiscountValue">
                  <i class="price__val-old">{{ variantPrice * quantity | formatPrice }} {{ $t('грн') }}</i>
                  <i class="price__val-new">{{ (variantPrice - categoryDiscountValue) * quantity | formatPrice }} {{ $t('грн') }}*</i>
                </span>
                <span class="price__val" v-else>{{ variantPrice * quantity | formatPrice }} {{ $t('грн') }}</span>
                <span class="price__slash" v-if="selectedVariant.weight">&nbsp;</span>
                <span class="price__val _weight" v-if="selectedVariant.weight">{{ selectedVariant.weight }}</span>
              </div>
              <div class="product__price _discount" v-if="categoryDiscount">
                *&nbsp;{{ $t('застосована знижка') }} -{{ product.category.discount_size }}%
                {{ $t('на товари з категорії') }} {{product.category.name}} ({{ $t('з') }} {{product.category.discount_from}}
                {{ $t('до') }} {{product.category.discount_to}})
              </div>
              <div class="product__price price _small" v-if="variantPackingPrice">
                <span class="price__title">{{ $t('Пакування') }}:</span>
                <span class="price__val">{{ variantPackingPrice | formatPrice }} {{ $t('грн') }}</span>
              </div>
              <div class="product__price price _total">
                <span class="price__title">{{ $t('Всього') }}:</span>
                <span class="price__val">{{ fullPrice | formatPrice }} {{ $t('грн') }}</span>
              </div>
            </div>
            <AlertInline class="product__price-row _warning _clear"
                         v-if="withRestaurantsRange"
                         :value="`${$t('Самовивіз тільки з')}: ${pickupRestaurantsText}`"/>
            <AlertInline class="product__price-row _warning" v-if="product.information_message && product.information_message.trim()" :value="product.information_message"/>
            <div class="product__row-buy">
              <Quantity class="product__quantity" v-model="quantity"/>
              <button
                  type="button"
                  @click="addToBasket"
                  class="product__btn-buy base-btn"
                  :title="`${$t('Замовити')} ${product.name}`"
              >
                {{ $t('Замовити') }}
              </button>
            </div>
            <div class="product__comments-wrap">
              <transition name="fade" mode="out-in">
                <button
                    v-if="!showComment"
                    type="button"
                    class="product__btn-add"
                    @click.prevent="onAddComment"
                >
                  <i>
                    <BaseSvg name="ico-comment"/>
                  </i>
                  {{ $t('Додати коментар') }}
                </button>
                <div v-else class="product__comments">
                  <button
                      type="button"
                      class="product__btn-add product__btn-fade"
                      @click.prevent="onRemoveComment"
                  >
                    <i>
                      <BaseSvg name="ico-close"/>
                    </i>
                  </button>
                  <BaseInput
                      textarea
                      row="3"
                      v-model.trim="comment"
                      :label="$t('Ваш коментар')"
                      class="product__comments-area"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <Additional id="additional"
                  v-if="allIngredientsList.length"
                  @changeIngredient="changeIngredient"
                  v-bind="ingredientsOptions"
                  class="product__additional has-anchor _offset"
      />
    </div>
    <AddToBasket :quantity="quantity" ref="add-el"/>
  </div>
</template>

<script>
  import CoolLightBox from 'vue-cool-lightbox'
  import Quantity from '@/components/Quantity'
  import AdditionalTags from '@/components/AdditionalTags'
  import Additional from './Additional'
  import stripHtml from '@/helpers/stripHtml'
  import {addToBasketAnimation} from '@/helpers/addToBasketAnimation'
  import AddToBasket from '@/components/AddToBasket'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import ProductSize from '@/components/ProductSize'
  import getCategoryDiscountPercent from '@/helpers/getCategoryDiscountPercent'
  import getDiscountValue from '@/helpers/getDiscountValue'
  import AlertInline from '@/components/AlertInline'

  export default {
    name: 'ProductBlock',
    components: {
      AlertInline,
      CoolLightBox,
      ProductSize,
      AddToBasket,
      Additional,
      AdditionalTags,
      Quantity
    },
    props: {
      product: Object,
      pendingProduct: Boolean
    },
    data() {
      return {
        imgDecor: require('../assets/img/product/decor.png'),
        stripHtml,
        indexImg: null,
        quantity: 1,
        showComment: false,
        comment: null,
        propertySelected: null,
        selectedVariant: null,
        ingredientsSelected: {}
      }
    },
    computed: {
      ...mapGetters('restaurant', ['restaurantsCount']),
      ...mapState({
        maxIngredientsQuantity(state) {
          return this.$get(state.settings.settings.product, 'max_pizza_ingredients_count') || 10
        }
      }),
      categoryDiscount() {
        return getCategoryDiscountPercent(this.product.category)
      },
      categoryDiscountValue() {
        return getDiscountValue(this.variantPrice, this.categoryDiscount)
      },
      allIngredientsList() {
        return this.product.category && this.product.category.ingredients || []
      },
      ingredientsOptions() {
        let options = {
          ingredients: this.allIngredientsList,
          ingredientsSelected: this.ingredientsSelected
        }
        if (this.maxIngredientsQuantity) {
          options.maxQuantity = Number(this.maxIngredientsQuantity)
        }
        return options
      },
      variantPrice() {
        return this.selectedVariant && this.selectedVariant.price + this.ingredientsPrice || 0
      },
      variantPackingPrice() {
        return this.selectedVariant && this.selectedVariant.packing_price || 0
      },
      ingredientsPrice() {
        let price = 0

        for (const key in this.ingredientsSelected) {
          const ingredient = this.ingredientsSelected[key]
          price += ingredient.ingredient.price * ingredient.quantity
        }

        return price
      },
      fullPrice() {
        return ((this.variantPrice - this.categoryDiscountValue) + this.variantPackingPrice) * this.quantity
      },
      withRestaurantsRange() {
        return this.product.category.pickup_restaurants && (this.product.category.pickup_restaurants.length < this.restaurantsCount)
      },
      pickupRestaurantsText() {
        let res = []
        this.product.category.pickup_restaurants.forEach(item => {
          res.push(`- ${item.name}`)
        })
        return `\n${res.join(',\n')}`
      }
    },
    methods: {
      ...mapActions('basket', ['addProductToBasket']),
      onImgClick(index) {
        this.indexImg = index
      },
      onSelectVariant(variant) {
        this.selectedVariant = variant
      },
      setDefault() {
        if (this.product.sauces && this.product.sauces.length) {
          this.propertySelected = this.product.sauces[0]
        }
        if (this.product.variants && this.product.variants.length) {
          this.onSelectVariant(this.product.variants[0])
        }
      },
      onAddComment() {
        this.showComment = true
      },
      onRemoveComment() {
        this.showComment = false
      },
      changeIngredient({ingredient, quantity}) {
        if (!quantity) {
          return this.$delete(this.ingredientsSelected, ingredient.id)
        }

        this.$set(this.ingredientsSelected, ingredient.id, {
          ingredient,
          quantity
        })
      },
      deleteIngredient({ingredient}) {
        this.changeIngredient({ingredient, quantity: 0})
      },
      addToBasket(event) {
        const cardObj = {
          product: this.product,
          quantity: this.quantity,
          variant: this.selectedVariant || null,
          property: this.propertySelected || null,
          additional_ingredients: Object.keys(this.ingredientsSelected).length ? this.ingredientsSelected : null,
          comment: null,
        }

        if (this.showComment) {
          cardObj.comment = this.comment || null
        }

        addToBasketAnimation(event, this.$refs['add-el'].$el, () => {
          this.addProductToBasket(cardObj)
        })
      }
    },
    created() {
      this.setDefault()
    }
  }
</script>

<style>
</style>
