<template>
  <div class="error-view" :class="{'container': isPage}">
    <div class="error-view__inner" :class="{'container-inner': isPage}">
      <h1 v-if="code" class="error-view__code">{{ code }}</h1>
      <h2 v-if="title"
          class="error-view__title">{{ title }}</h2>
      <div class="error-view__text  text" v-if="errorMessage">
        <p>
          {{ errorMessage }}
        </p>
      </div>
      <div class="error-view__footer">
        <router-link :to="{name: 'home'}"
                     class="error-view__btn  base-btn  base-btn--blue">
          {{$t('На головну')}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {setMetaRobots} from '@/helpers/metaRobots'

  export default {
    props: {
      code: {
        type: [String, Number],
        default: 404
      },
      title: null,
      message: null,
      showMessage: {
        type: Boolean,
        default: true
      },
      isPage: Boolean
    },
    data(vm) {
      return {
        errorMessage: vm.showMessage ? vm.message || this.$t('Сторінку не знайдено. Неправильно набрано адресу або такої сторінки на сайті більше не існує') : null
      }
    },
    created() {
      if (this.code >= 400 && this.code < 500) {
        setMetaRobots('noindex, follow', this.code)
      }
    }
  }
</script>
