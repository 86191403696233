<template>
  <div class="bread-crumbs container">
    <div class="bread-crumbs__inner container-inner">
      <ul class="bread-crumbs__list">
        <li :key="'home'" class="bread-crumbs__item">
          <router-link :to="{name: 'home'}">{{$t('Головна')}}</router-link>
        </li>
        <li class="bread-crumbs__item"
            v-for="(item, index) in links"
            :key="`link_${index}`">
          <router-link v-if="item.to"
                       :to="item.to">{{item.name}}</router-link>
          <span v-else>{{item.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    links: {
      type: Array
    }
  }
}
</script>

<style>

</style>
