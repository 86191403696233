<template>
  <div class="additional__item"
       :class="{'_active': quantity, '_disabled': disabled}"
  >
    <div class="additional__item-wrap">
      <div class="additional__item-inner"
           @click.prevent="onSelect"
      >
        <div class="additional__item-img-wrap">
          <img :src="$imgPlaceholder"
               :data-src="ingredient.image_xs"
               :alt="ingredient.name"
               class="lazyload"
          >
        </div>
        <div class="additional__item-label">{{ingredient.name}}</div>
        <div class="additional__item-price _small">{{ingredient.portion_size}}</div>
        <div v-if="!hidePrice" class="additional__item-price">{{ingredient.price | formatPrice}} {{$t('грн')}}</div>
      </div>
      <button type="button"
              class="additional__item-remove"
              v-if="quantity"
              @click.prevent.stop="onUnSelect"
      >
        <BaseSvg name="ico-close"/>
      </button>
      <Quantity :value="quantity"
                :min="0"
                :max="maxQuantity"
                @input="onChangeQuantity"
                @click.native.prevent.stop=""
                class="additional__item-quantity"
      />
    </div>
  </div>
</template>

<script>
  import Quantity from './Quantity'
  export default {
    name: 'AdditionalItem',
    components: {Quantity},
    props: {
      ingredient: null,
      quantity: Number,
      maxQuantity: Number,
      disabled: {
        type: Boolean,
        default: false,
      },
      hidePrice: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onChangeQuantity(quantity) {
        if (this.disabled && quantity > this.quantity) return

        this.$emit('changeIngredient', {
          quantity,
          ingredient: this.ingredient
        })
      },
      onSelect() {
        if (this.quantity) return
        this.onChangeQuantity(1)
      },
      onUnSelect() {
        this.onChangeQuantity(0)
      },
    }
  }
</script>

<style>

</style>
