<template>
  <ErrorView v-if="error" is-page/>
  <main v-else class="page-product"
        :class="{_loading: pending}">
    <BasePreloader v-if="pending"/>
    <template v-else>
      <BreadCrumbs :links="crumbsLinks"/>
      <Product :product="product"
               ref="product"/>
      <PopularBlock :title="$t('З цим купують')"
                    :products="relevantProducts"
                    :pending="pendingRelevant"
                    v-if="pendingRelevant || !pendingRelevant && relevantProducts.length > 0"/>
      <PopularBlock :title="$t('Суперпропозиція')"
                    :products="popularProducts"
                    :pending="pendingPopular"
                    v-if="pendingPopular || !pendingPopular && popularProducts.length > 0"/>
    </template>
  </main>
</template>

<script>
  import {http} from '@/axios'
  import PopularBlock from '@/components/PopularBlock'
  import Product from '@/components/Product'
  import BreadCrumbs from '@/components/BreadCrumbs'
  import ErrorView from '@/components/ErrorView'
  import stripHtml from '@/helpers/stripHtml'
  import {languages} from '@/i18n'

  // import Features from "../components/Features";

  export default {
    name: 'ProductPage',
    components: {
      ErrorView,
      BreadCrumbs,
      // Features,
      Product,
      PopularBlock
    },
    data() {
      return {
        product: {},
        pending: false,
        pendingProduct: false,
        popularProducts: [],
        pendingPopular: false,
        relevantProducts: [],
        pendingRelevant: false,
        crumbsLinks: [],
        error: null
      }
    },
    metaInfo() {
      return {
        ...this.metaComputed
      }
    },
    methods: {
      loadData() {
        this.pendingProduct = true
        this.error = null
        const productId = this.$route.params.id
        return http.get(`api/product/${productId}`).then(({data}) => {
          this.product = data.item
          this.createCrumbs()
        }).catch((error) => {
          this.error = error
        }).finally(() => {
          this.pendingProduct = false
        })
      },
      loadPopularProducts() {
        if (!this.productCategory) return
        this.pendingPopular = true
        return http.get(`/api/top-products?categories=${this.productCategory}`)
            .then(({data}) => {
              this.popularProducts = data.data
            }).finally(() => {
              this.pendingPopular = false
            })
      },
      loadRelevantProducts() {
        if (!this.productId) return
        this.pendingRelevant = true
        return http.get(`/api/with-this-buy?products=${this.productId}`)
            .then(({data}) => {
              this.relevantProducts = data.data
            }).finally(() => {
              this.pendingRelevant = false
            })
      },
      loadAll() {
        this.pending = true
        Promise.all([
          this.loadData(),
          this.loadPopularProducts(),
          this.loadRelevantProducts()
        ]).finally(() => {
          this.pending = false
        })
      },
      createCrumbs() {
        this.crumbsLinks = [
          {
            name: this.product.category.name,
            to: {
              name: 'category',
              params: {
                categoryId: this.product.category.slug
              },
              hash: '#catalog',
            }
          },
          {
            name: this.product.name
          }
        ]
      },
      resetData() {
        this.product = {}
        this.pendingProduct = true
        this.popularProducts = []
        this.pendingPopular = true
        this.crumbsLinks = []
        this.relevantProducts = []
        this.pendingRelevant = false
        this.error = null
        window.scrollTo({
          top: 0
        })
      }
    },
    computed: {
      productCategory() {
        return this.$get(this.product, 'category.id', null)
      },
      productId() {
        return this.$get(this.product, 'id', null)
      },
      metaComputed() {
        if (!this.product) return
        const data = {
          link: [
            {
              vmid: 'canonical',
              rel: 'canonical',
              href: `${window.location.origin}${this.$route.path}`
            }
          ],
          meta: []
        }
        let clearRoute = this.$route.path.slice(4)
        for (let langItem of languages) {
          data.link.push({
            vmid: `alternate_${langItem.code}`,
            rel: 'alternate',
            hreflang: langItem.code,
            href: `${window.location.origin}/${langItem.show_code}/${clearRoute}`
          })
        }

        const title = this.product.meta_title || this.product.name || null
        const description = this.product.meta_description ||
            (title && this.$get(this.product, 'description') ?
                `${this.product.name} - ${stripHtml(this.product.description)}` : null)

        if (title) {
          data.title = title
          data.meta.push({
                vmid: 'og:title',
                property: 'og:title',
                content: title
              },
              {
                vmid: 'twitter:title',
                name: 'twitter:title',
                content: title
              })
        }

        if (description) {
          data.meta.push({
                vmid: 'description',
                name: 'description',
                content: description
              },
              {
                vmid: 'twitter:description',
                name: 'twitter:description',
                content: description
              },
              {
                vmid: 'og:description',
                property: 'og:description',
                content: description
              })
        }

        return data
      }
    },
    created() {
      this.loadAll()
    },
    watch: {
      '$route.params.id'() {
        this.resetData()
        this.loadAll()
      },
      productCategory(to) {
        if (to) {
          this.loadPopularProducts()
        }
      },
      productId(to) {
        if (to) {
          this.loadRelevantProducts()
        }
      }
    }
  }
</script>

<style>
</style>
